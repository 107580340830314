<!--电能设置--基本设置---->
<template>
  
  <div id="energyDataOutput" v-loading="loading" :element-loading-text="$t('alarm.a0')">
     <!-- 页面标题 -->
    <div class="header-title">
      <span>{{ $t("energy.enedata044") }}</span>
    </div>

     <div class="header">
      <div class="header-left">
        <div class="header-condition">
            <span>{{ $t("energy.enedata002") }}</span>
            <a-radio-group v-model="rpt" button-style="solid" @change="rptChange">
                <a-radio-button  v-for="(itme, index) in this.outputTypeList"
                    :key="index"
                    :value="itme.no"> {{ itme.text }}</a-radio-button>
            </a-radio-group>
        </div>
      </div>
     </div>

    <div class="header">
      <div class="header-left">
          <div class="header-condition">
           <span>{{ $t("energy.enedata045") }}</span>
            <a-select v-model="pointType" style="width: 300px" @change="pointTypeChange" :disabled="pointDisable">
              <a-select-option
                  v-for="(itme, index) in this.pointTypeList"
                  :key="index"
                  :value="itme.no"
                  >
                  {{ itme.text }}
                </a-select-option>
            </a-select>
           
          </div>
      </div>
    </div>


    <div class="header">
      <div class="header-left">
        <div class="header-condition" v-if="rpt!=3 && rpt!=2">
          <span>{{ $t("energy.enedata048") }}</span>
          <a-select v-model="year" style="width: 120px" @change="yearChange">
              <a-select-option
                  v-for="(itme, index) in this.yearList"
                  :key="index"
                  :value="itme"
                  >
                  {{ itme }}
              </a-select-option>
          </a-select>
        </div>
        
        <div class="header-condition" v-if="rpt ==3">
            <span>{{ $t("energy.enedata048") }}</span>
            <a-range-picker
              format="YYYY/MM/DD"
              :value="dates"
              :disabled-date="disabledDate"
              @change="selDateChange"
            />
        </div>

        <div class="header-condition" v-if="rpt ==2">
          <span>{{ $t("energy.enedata048") }}</span>
          <div>
              <a-month-picker
                format="YYYY/MM"
                v-model="dateFrom"
                :disabled-date="disabledStartDate"
                @change="handleSMonthChange"
              />
              ~
               <a-month-picker
                format="YYYY/MM"
                v-model="dateTo"
                :disabled-date="disabledEndDate"
                @change="handleEMonthChange"
              />
          </div>
         
        </div>

      </div>
      
      <a-button type="primary" style="width: 120px"  @click="csvout()"> {{ $t("energy.enedata047") }} </a-button>
    </div>
    <div class="main">
        <a-list size="small" bordered :data-source="notes">
          <a-list-item slot="renderItem" slot-scope="item">
            {{ item }}
          </a-list-item>
        </a-list>
    </div> 
   
  </div>
</template>



<script>
import { getEnergyDataOutputData,downloadEnergyCSVDataWithURL } from "../../../api/energy";
import { downloadUrl } from "../../../utils/utils";
import moment from "moment";

export default {
  name: "energyDataOutput",
  data() {
    
    return {
      loading: false,
      data:null,
      columns:null,
      outputTypeList:[],
      rpt:'',
      reportName:'',
      yearList:[],
      year:0,
      pointTypeList:[],
      pointType:'',
      pointTypeName:'',
      dateFrom:moment(),
      dateTo:moment(),
      mode1: 'time',
      monthMode: ['month', 'month'],
      pointDisable:false,
      notes:[],
      dates:[],
      endOpen:false,
    };
  },
  computed: {
    
  },
  mounted() { 
    this.initPage();
  },
  beforeDestroy() {
   
  },
  methods:{
    csvout(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            pointType:this.pointType,
        };

        if(this.rpt=='1' || this.rpt=='4' || this.rpt=='5'){
          params.year = this.year;
        }else if(this.rpt=='2'){
          params.dateFrom = this.dateFrom.format('YYYY/MM');
          params.dateTo = this.dateTo.format('YYYY/MM');
        }else if(this.rpt=='3'){
          params.dateFrom = this.dateFrom.format('YYYY/MM/DD');
          params.dateTo = this.dateTo.format('YYYY/MM/DD');
        }
        console.log("downloadEnergyCSVDataWithURL Params",params);
        this.loading = true;

        downloadEnergyCSVDataWithURL('bivale/energy/data/output/download',params)
        .then((res) => {
            let name  = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.reportName+'-';
            if(this.rpt!='4'){
              name +=this.pointTypeName+'-'
            }
            name +=moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadEnergyCSVDataWithURL Result",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.$message.error(this.$t('energy.enedata376'));
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    rptChange(e){
      console.log('rptChange',e);
      let t = parseInt(e.target.value);
      if(this.pointDisable){
         this.pointDisable = false;
         this.pointType = this.pointTypeList[0].no;
         this.pointTypeName = this.pointTypeList[0].text;
      }
      this.reportName = this.outputTypeList[t-1].text;
      switch(t){
        case 1:
          break;
          case 2:
            this.initDate();
          break;
          case 3:
            this.initDate();
          break;
          case 4:
            this.pointDisable = true;
            this.pointType = '';
          break;
          case 5:

          break;
      }
    },
    yearChange(e){
       console.log('yearChange',e);
    },
    pointTypeChange(e){
      console.log('pointTypeChange',e);
      let t = parseInt(e);
      this.pointTypeName = this.pointTypeList[t-1].text;
    },
    initPage(){
      let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getEnergyDataOutputData(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
                this.outputTypeList = res.data.outputTypeList;
                this.yearList = res.data.yearList;
                this.pointTypeList = res.data.pointTypeList;
               
                this.pointType = res.data.pointTypeList[0].no;
                this.pointTypeName = res.data.pointTypeList[0].text;
                this.rpt = res.data.outputTypeList[0].no;
                this.reportName = res.data.outputTypeList[0].text;
                this.year = res.data.yearList[0];

                let idx = 50;
                this.notes.push(this.$t('energy.enedata049'));
                for (let i = 0; i < this.outputTypeList.length; i++) {
                  const element =  this.outputTypeList[i];
                  this.notes.push(element.text+":"+this.$t('energy.enedata0'+(idx+i)));
                }
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    
    selDateChange(dates,dateStrings){
      
      console.log('selDateChange1>', dates);
      console.log('selDateChange2>', dateStrings);
      console.log('selDateChange1>', dates[1].diff(dates[0],'day'));
      if(this.rpt == '3'){
        const num = dates[1].diff(dates[0],'day');
        const year1 =  dates[0].year();
        const year2 =  dates[1].year();
        const month1 = dates[0].month();
        const month2 = dates[1].month();
        
        let dayNums = 90;
        
        if(num>dayNums){
          this.dates = new Array();
          this.dates.push(this.dateFrom);
          this.dates.push(this.dateTo);
          this.$message.error(this.$t('energy.enedata057'));
          return;
        }
        
      }

      this.dates = dates;
      this.dateFrom = dates[0];
      this.dateTo = dates[1];

    },
    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }
      return (startValue.valueOf() > endValue.valueOf() 
      || startValue > moment().endOf('month'));
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
     return (startValue.valueOf() >= endValue.valueOf() 
     || endValue > moment().endOf('month') );
    },
    handleSMonthChange(date,dateStr){
      const endValue = this.dateTo;
      if(endValue){
        if(endValue.diff(date,'day')>183){
          this.initDate();
          this.$message.error(this.$t('energy.enedata056'));
          return;
        }
      }
    },
    handleEMonthChange(date,dateStr){
     const startValue = this.dateFrom;
      if(startValue){
        if(date.diff(startValue,'day')>183){
          this.initDate();
          this.$message.error(this.$t('energy.enedata056'));
          return;
        }
      }
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    disabledDate(startValue) { 
      const endValue = moment();
      if (!startValue || !endValue) {
        return false;
      }

      let now = moment();
      
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= now.endOf()
      );
    },
    initDate(){
      
      
         if(this.rpt == '2'){
          this.dateFrom = moment();
          this.dateTo   = moment();
         }else if(this.rpt == '3'){
          let now = moment();
          let c = now.format('YYYY/MM')+"/01";
          this.dateFrom = moment(c);
          this.dateTo = now;
         }

        this.dates.push(this.dateFrom);
        this.dates.push( this.dateTo);
    },
  },
  components: {
    
  },
};
</script>

<style scoped>

#energyDataOutput {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background-color: #7682ce;
  margin-right: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.header-left {
  display: flex;
  justify-content: flex-start;
}
.header-condition {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.main{
    width: 100%;
    height:100%;
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px #bdbcbc;
}

input {
  width: 14%;
}
button {
  width: 80px;
}
.footer button {
  margin-left: 15px;
}
</style>


